/* eslint-disable react-hooks/exhaustive-deps */

/** @jsx jsx */
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Box, Heading, jsx } from 'theme-ui'
import ContentFaqQuestion from '~/components/Content/Faq/Question'
import useDebounce from '~/hooks/utils/use-debounce'

const ContentFaqTopic = ({
  topic,
  id,
  setActiveTopic,
  forwardSx = {},
  forwardSxQuestion = {}
}) => {
  const [scrollAmount, setScrollAmount] = useState(0)
  const debouncedScrollAmount = useDebounce(scrollAmount, 100)
  useEffect(() => {
    if (setActiveTopic) document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll(e) {
    setScrollAmount(window.scrollY)
  }

  useEffect(() => {
    if (debouncedScrollAmount && ref.current) {
      if (id === 0) {
        if (
          debouncedScrollAmount < ref.current.offsetTop ||
          debouncedScrollAmount >= ref.current.offsetTop - 300
        ) {
          setActiveTopic(topic.id)
        }
      } else {
        if (debouncedScrollAmount >= ref.current.offsetTop - 300) {
          setActiveTopic(topic.id)
        }
      }
    }
  }, [debouncedScrollAmount])
  const ref = useRef()
  return (
    <Box ref={ref} sx={{ flexGrow: 3, pt: [id === 0 ? 0 : '60px', '4.5rem'] }}>
      <Heading
        as="h2"
        sx={{
          fontSize: '14px',
          marginBottom: '15px',
          fontFamily: 'secondary',
          textTransform: 'uppercase',
          ...forwardSx
        }}
      >
        {topic.name}
      </Heading>
      <Box sx={{ ...forwardSxQuestion }}>
        {topic.questions.map((question, i) => {
          return (
            <ContentFaqQuestion key={question.id + i} question={question} />
          )
        })}
      </Box>
    </Box>
  )
}

export default ContentFaqTopic

ContentFaqTopic.propTypes = {
  faq: PropTypes.shape({
    name: PropTypes.string,
    questions: PropTypes.array
  })
}
