import PropTypes from 'prop-types'
import React from 'react'
import ImageHero from '~/components/Generic/ImageHero'

const ContentHero = ({
  objectPosition,
  ignoreText,
  hero,
  gradient = false,
  ...props
}) => {
  return (
    <ImageHero
      heading={!ignoreText && hero.heading}
      subheading={!ignoreText && hero.subheading}
      image={hero.image}
      gradient={gradient}
      objectPosition={objectPosition}
      {...props}
    />
  )
}

ContentHero.propTypes = {
  hero: PropTypes.shape({
    heading: PropTypes.shape({
      heading: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
      fluid: PropTypes.shape(),
    }).isRequired,
    subheading: PropTypes.shape({
      subheading: PropTypes.string,
    }),
  }),
  gradient: PropTypes.bool,
}

export default ContentHero
