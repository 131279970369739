/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import CloseIcon from './CloseIcon'

const ContentFaqQuestion = ({ question }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      sx={{
        borderColor: 'primary',
        borderBottom: '1px solid',
        overflow: 'hidden',
        ':first-child': {
          borderTop: '1px solid'
        }
      }}
    >
      <Flex
        onClick={() => setOpen(!open)}
        sx={{
          cursor: 'pointer',
          flexDirection: 'row',
          justifyContent: 'space-between',
          py: '30px'
        }}
      >
        <Heading
          as="h3"
          sx={{
            paddingRight: '1rem',
            variant: 'text.h5',
            lineHeight: ['22px', '24px'],
            textTransform: 'none',
            color: 'inherit'
          }}
        >
          {question.question.question}
        </Heading>
        <Flex
          sx={{
            alignItems: 'center',
            flexShrink: 0
          }}
        >
          <CloseIcon open={open} color="governorBay" />
        </Flex>
      </Flex>
      <Box
        sx={{
          display: open ? 'block' : 'none',
          mt: '-15px',
          mb: ['20px', '30px'],
          p: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: ['22px', '24px'],
            fontSize: ['18px', '20px'],
            margin: 0
          }
        }}
        dangerouslySetInnerHTML={{
          __html: question.answer.childMarkdownRemark.html
        }}
      />
    </Box>
  )
}

ContentFaqQuestion.propTypes = {
  question: PropTypes.shape({
    answer: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    question: PropTypes.shape({
      question: PropTypes.string
    })
  })
}

export default ContentFaqQuestion
