/** @jsx jsx */
import ActionButton from '~/components/Content/Contact/ActionButton'
import { Box, Flex, jsx } from 'theme-ui'
import Img from 'gatsby-image'

const ContactFooter = ({ section }) => {
  return (
    <Flex
      sx={{
        pb: ['100px', '170px'],
        px: ['30px', '10vw'],
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '150px',
        flexDirection: ['column', 'row']
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: ['200px', '380px'],
          mb: '20px'
        }}
      >
        <Img
          fluid={section.media[0]?.media?.fluid}
          alt={section.media[0]?.media?.title}
          sx={{
            height: 'auto',
            alignContent: 'center'
          }}
        />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: ['center', 'start']
        }}
      >
        <Box
          sx={{
            width: 'calc(80% - 20px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'grape',
            textAlign: ['center', 'left'],
            pb: '30px',
            h2: {
              fontSize: ['32', 5],
              margin: 0,
              lineHeight: ['32px', '36px'],
              textTransform: 'uppercase',
              width: ['8em', '100%'],
              mb: '30px'
            },
            p: {
              fontSize: ['18px', 3],
              lineHeight: ['22px', '24px'],
              margin: 0
            }
          }}
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html
          }}
        />
        <ActionButton
          text={section.media[0]?.cta.text}
          action={section.media[0]?.cta?.page?.slug}
          forwardSx={{ maxWidth: '195px' }}
        />
      </Flex>
    </Flex>
  )
}

export default ContactFooter
